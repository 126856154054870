<template>
    <v-toolbar
      :color="color"
      dense
      height="44"
      flat
      class="pa-0"
    >
      <v-btn
        icon
        small
        class="m-0"
        color="black"
        v-bind="$attrs"
        v-on="$listeners"
        @click="onClickBack"
        v-if="dataSources.actives.backControl"
      >
        <v-icon>navigate_before</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title
        class="f-s-16">
        <slot name="pageTitle"></slot>
      </v-toolbar-title>
      <v-spacer></v-spacer>
<!--      <wap-call-phone-icon-->
<!--      ></wap-call-phone-icon>-->
    </v-toolbar>
</template>

<script>
// import WapCallPhoneIcon from '@/components/base/WapCallPhoneIcon'

export default {
  name: 'WapNavigationBar',
  components: {
  },
  props: {
    color: {
      type: String,
      default: 'transparent'
    }
  },
  data () {
    return {
      dataSources: {
        actives: {
          backControl: false
        }
      }
    }
  },
  created () {
  },
  methods: {
    onClickBack () {
      this.$emit('clickBack')
    }
  }
}
</script>

<style lang="sass" scoped>

</style>
