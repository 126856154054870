<template>
  <div class="wap-navigation-layout">
    <div class="head-panel">
      <wap-navigation-bar
        @clickBack="onClickLeft"
      >
        <div slot="pageTitle">
          {{ layouts.data.title }}
        </div>
      </wap-navigation-bar>
    </div>
    <div class="main-panel">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import WapNavigationBar from '@/components/base/WapNavigationBar'
import { Tool } from '@/assets/js/util'

const tool = new Tool()

export default {
  name: 'NavigationLayout',
  components: {
    WapNavigationBar
  },
  data () {
    return {
      layouts: {
        data: {
          title: ''
        }
      }
    }
  },
  watch: {
    '$route' () {
      this.layouts.data.title = this.$route.meta.title
    }
  },
  mounted () {
    this.layouts.data.title = this.$route.meta.title
  },
  methods: {
    onClickLeft () {
      if (tool.isNotEmpty(this.$route.meta) && tool.isNotEmpty(this.$route.meta.to)) {
        this.$router.push(this.$route.meta.to)
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.wap-navigation-layout
  & .head-panel
    position: fixed
    top: 0
    z-index: 1
    width: 100%
    padding: 0 24px
    background-color: white

.main-panel
  margin-top: 44px
  //margin-bottom: 74px
</style>

<style lang="sass">
.wap-navigation-layout
  & .main-content
    padding: 0 24px 16px 24px
  & .footer-wrap
    position: fixed
    bottom: 0
    z-index: 1
    width: 100%
    padding: 12px 24px

</style>
